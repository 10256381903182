import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate} from 'react-router-dom';
import Layout from "@components/manage/Layout";
import { loadTossPayments } from "@tosspayments/tosspayments-sdk";
import Alert from '../../components/common/Alert';
import { useBillingInformationQuery, useIssueBillingKeyMutation } from '../../services/paymentsService';
import { useSelector } from "react-redux";
import { InfoIcon } from 'lucide-react';
import config from '@config';

const priceConfig = {
  KR: { currency: 'KRW', monthlyPrice: 29900, symbol: '₩' },
  US: { currency: 'USD', monthlyPrice: 29.99, symbol: '$' },
  JP: { currency: 'JPY', monthlyPrice: 3300, symbol: '¥' },
  CN: { currency: 'CNY', monthlyPrice: 199, symbol: '¥' },
};

function PaymentPage() {
  const navigate = useNavigate();
  const user = useSelector(state => state.auth.user);

  //get query string
  //ex http://localhost:3000/settings/payment?customerKey=66cbfcbb971be6ba23ac5954&authKey=bln_QGWdWPEzGRE
  //customerKey, authKey 값이 넘어 오면 monthly
  const queryParams = new URLSearchParams(window.location.search);

  console.log('queryParams', queryParams);

  //값출력하려면 pa
  

  const { data: billingInformation } = useBillingInformationQuery();
  const [issueBillingKey] = useIssueBillingKeyMutation();

  const { t, i18n } = useTranslation();
  //params.customerKey && params.authKey 값이 넘어 오면 monthly
  const [billingCycle, setBillingCycle] = useState(queryParams.get('customerKey') && queryParams.get('authKey') ? 'monthly' :  'annual');
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [currentPlan, setCurrentPlan] = useState(null);

  const [payment, setPayment] = useState(null);
  const [isBillingKeyIssued, setIsBillingKeyIssued] = useState(false);
  const [paymentWidget, setPaymentWidget] = useState(null);
  const [readyPayment, setReadyPayment] = useState(false);

  const getCurrentCountry = () => {
    switch(i18n.language) {
      case 'ko': return 'KR';
      case 'en': return 'US';
      case 'ja': return 'JP';
      case 'zh': return 'CN';
      default: return 'US';
    }
  };

  const country = getCurrentCountry();
  const { currency, monthlyPrice, symbol } = priceConfig[country];
  const annualPrice = monthlyPrice * 12 * 0.8;

  useEffect(() => {
    setIsSubscribed(false);
    setCurrentPlan(null);

    if(paymentWidget === null) return;

    paymentWidget.setAmount({
      currency: currency,
      value: billingCycle === 'annual' ? annualPrice : monthlyPrice,
    });
  }, [billingCycle]);

  useEffect(() => {
    if(billingInformation) {
      console.log('billingInformation', billingInformation);
      setIsBillingKeyIssued(billingInformation.isExist);
    }
  }, [billingInformation]);

  useEffect(() => {
    // alert(queryParams.get('authKey'));
    //한번만 실행
    if(queryParams.get('customerKey') && queryParams.get('authKey')) {
      console.log('customerKey', queryParams.get('customerKey'));
      console.log('authKey', queryParams.get('authKey'));
      issueBillingKey({
        customerKey: queryParams.get('customerKey'),
        authKey: queryParams.get('authKey'),
      }).then(() => {
        navigate('/settings/payment');
      });
    }
  }, []);

  useEffect(() => {
    async function fetchPayment() {
      try {
        const tossPayments = await loadTossPayments(config.TOSSPAY_CLIENT_SECRET_KEY);

        const payment = tossPayments.payment({
          customerKey: user.id
        })

        console.log('payment', payment);

        setPayment(payment);
      } catch (error) {
        alert(error);
        console.error("Error fetching payment widget:", error);
      }
    }

    async function fetchPaymentWidgets() {
      try {
        const tossPayments = await loadTossPayments(config.TOSSPAY_CLIENT_SECRET_KEY_FOR_WIDGET);

        const widgets = tossPayments.widgets({
          customerKey: user.id
        });

        setPaymentWidget(widgets);
      } catch (error) {
        console.error("Error fetching payment widget:", error);
      }
    }

    fetchPayment();
    fetchPaymentWidgets();
  }, [billingCycle]);
    

  useEffect(() => {
    async function renderPaymentWidgets(){
      if(paymentWidget === null || billingCycle === 'monthly') return;
      
      // 연간 결제일 때만 위젯 렌더링
      await paymentWidget.setAmount({
        currency: currency,
        value: annualPrice,
      });

      await Promise.all([
        paymentWidget.renderPaymentMethods({
          selector: '#payment-method',
          variantKey: 'DEFAULT'
        }),
        paymentWidget.renderAgreement({
          selector: "#agreement",
          variantKey: "AGREEMENT"
        }),
      ])

      setReadyPayment(true);
    }

    renderPaymentWidgets();
  }, [paymentWidget, billingCycle]);

  const formatPrice = (price) => {
    return new Intl.NumberFormat(i18n.language, { style: 'currency', currency: currency }).format(price);
  };

  const handleProceed = async () => {
    try {
      if (billingCycle === 'monthly') {
        if(isBillingKeyIssued) {
          
        } else {
          // 월간 정기결제 - 빌링키 발급 요청
          await payment?.requestBillingAuth({
            method: "CARD", // 자동결제(빌링)은 카드만 지원합니다
            successUrl: window.location.origin + "/settings/payment", // 요청이 성공하면 리다이렉트되는 URL
            failUrl: window.location.origin + "/fail", // 요청이 실패하면 리다이렉트되는 URL
            customerEmail: user.id,
            customerName: user.name,
          });
        }
      } else {
        // 연간 단건 결제 - 위젯 사용
        await paymentWidget.requestPayment({
          orderId: `ORD-${Date.now()}`,
          orderName: `${t('payment.proPlan')} (연간)`,
          customerEmail: 'customer@example.com',
          customerName: 'Customer',
        });
      }
    } catch (error) {
      console.error('Payment request failed:', error);
    }
  };

  return (
    <Layout>
      <div className="max-w-2xl mx-auto py-8 px-4 sm:px-6 lg:px-8">
        <div className="bg-white shadow-lg rounded-lg overflow-hidden">
          <div className="px-6 py-8">
            <h2 className="text-xl font-semibold mb-4">{t('payment.proPlan')}</h2>
            <div className="flex justify-center space-x-4 mb-8">
              <button
                className={`px-6 py-2 rounded-full text-sm font-medium transition-colors duration-200 ${
                  billingCycle === 'monthly' 
                    ? 'bg-indigo-600 text-white' 
                    : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
                }`}
                onClick={() => setBillingCycle('monthly')}
              >
                {t('payment.monthly')}
              </button>
              <button
                className={`px-6 py-2 rounded-full text-sm font-medium transition-colors duration-200 ${
                  billingCycle === 'annual' 
                    ? 'bg-indigo-600 text-white' 
                    : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
                }`}
                onClick={() => setBillingCycle('annual')}
              >
                {t('payment.annual')}
              </button>
            </div>
            <div className="text-center mb-8">
              <p className="text-4xl font-bold text-gray-900">
                {billingCycle === 'annual' 
                  ? t('payment.annualPrice', { price: formatPrice(annualPrice) })
                  : t('payment.monthlyPrice', { price: formatPrice(monthlyPrice) })
                }
              </p>
              {billingCycle === 'annual' && (
                <p className="text-indigo-600 font-medium mt-2">
                  {t('payment.annualDiscount')}
                </p>
              )}
            </div>

            {billingCycle === 'annual' && (
              <>
                <div id="payment-method" className="mb-6"></div>
                <div id="agreement" className="mb-6"></div>
              </>
            )}

            <div className="bg-gray-50 rounded-lg p-4 mb-6">
              <div className="flex items-start">
                <InfoIcon className="w-5 h-5 text-indigo-600 mt-0.5 mr-3" />
                <div>
                  <h3 className="font-medium text-gray-900">결제 안내</h3>
                  {billingCycle === 'monthly' ? (
                    <>
                      <p className="text-sm text-gray-600 mt-1">• 첫 결제 시 카드 등록이 필요합니다</p>
                      <p className="text-sm text-gray-600">• 매월 자동으로 결제됩니다</p>
                      <p className="text-sm text-gray-600">• 다음 결제일: {new Date(Date.now() + 30 * 24 * 60 * 60 * 1000).toLocaleDateString()}</p>
                      <p className="text-sm text-gray-600">• 언제든 해지 가능합니다</p>
                    </>
                  ) : (
                    <>
                      <p className="text-sm text-gray-600 mt-1">• 1년 동안 이용 가능합니다</p>
                      <p className="text-sm text-gray-600">• 일시불로 결제됩니다</p>
                      <p className="text-sm text-gray-600">• 만료일: {new Date(Date.now() + 365 * 24 * 60 * 60 * 1000).toLocaleDateString()}</p>
                    </>
                  )}
                </div>
              </div>
            </div>

            {(readyPayment || billingCycle === 'monthly') && (
              <button
                onClick={handleProceed}
                className="w-full bg-indigo-600 text-white py-3 px-4 rounded-lg hover:bg-indigo-700 transition duration-300 font-medium"
              >
                {billingCycle === 'monthly' 
                  ? isBillingKeyIssued ? `월 ${formatPrice(monthlyPrice)}으로 구독 시작하기`
                  : `카드 등록 시작하기`
                  : `연 ${formatPrice(annualPrice)}으로 구독 시작하기`}
              </button>
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default PaymentPage;