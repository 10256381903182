import React, { useEffect, useState, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useParams, useNavigate } from 'react-router-dom';
import { useGetGuideQuery, useNewGuideMutation, useUpdateGuideMutation } from '@services/guideService';
import Layout from '@components/manage/Layout';
import { X, ToggleRight, Lock } from 'lucide-react';
import constants from '@constants';
import InfoTooltip from '@components/common/InfoTooltip';
import GuidePreview from '@components/manage/guide/GuidePreview';
import LoadingSpinner from '@components/LoadingSpinner';
import LoadingOverlay from '@components/common/LoadingOverlay';
import config from '@config';

const GuideForm = () => {
  const { guide_id } = useParams();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [image, setImage] = useState(null);
  const [deleteImageId, setDeleteImageId] = useState(null);
  const [selectedLang, setSelectedLang] = useState('en');

  const fileInputRef = useRef(null);

  const [newGuide, { isLoading: isNewing, isError: newError }] = useNewGuideMutation();
  const [updateGuide, { isLoading: isUpdating, isError: updateError }] = useUpdateGuideMutation();
  const { data: existingGuide, isLoading: isLoadingGuide } = useGetGuideQuery(
    { id: guide_id },
    {
      skip: !guide_id,
      refetchOnMountOrArgChange: true,
    }
  );

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState(null);

  const validationSchema = Yup.object().shape({
    category: Yup.string().trim().required(t('guide.validation.category_required')),
    support_language: Yup.array().of(Yup.string()).min(1, t('guide.validation.language_required')),
    is_private: Yup.boolean().required(),
    password: Yup.string()
      .trim()
      .when('is_private', {
        is: true,
        then: (schema) => schema.required(t('guide.validation.password_required')),
        otherwise: (schema) => schema.optional(),
      }),
    duration: Yup.object()
      .shape({
        hours: Yup.number().min(0).max(23).required(t('guide.validation.hours_required')),
        minutes: Yup.number().min(0).max(59).required(t('guide.validation.minutes_required')),
      })
      .test('duration', t('guide.validation.duration_required'), function (value) {
        return value.hours >= 0 && value.minutes >= 0 && (value.hours > 0 || value.minutes > 0);
      }),
    active: Yup.boolean().required(),
    title_en: Yup.string()
      .trim()
      .when('support_language', {
        is: (val) => val && val.includes('en'),
        then: (schema) => schema.required(t('guide.validation.title_required')),
      }),
    title_ko: Yup.string()
      .trim()
      .when('support_language', {
        is: (val) => val && val.includes('ko'),
        then: (schema) => schema.required(t('guide.validation.title_required')),
      }),
    title_zh: Yup.string()
      .trim()
      .when('support_language', {
        is: (val) => val && val.includes('zh'),
        then: (schema) => schema.required(t('guide.validation.title_required')),
      }),
    title_ja: Yup.string()
      .trim()
      .when('support_language', {
        is: (val) => val && val.includes('ja'),
        then: (schema) => schema.required(t('guide.validation.title_required')),
      }),
    subtitle_en: Yup.string()
      .trim()
      .when('support_language', {
        is: (val) => val && val.includes('en'),
        then: (schema) => schema.required(t('guide.validation.subtitle_required')),
      }),
    subtitle_ko: Yup.string()
      .trim()
      .when('support_language', {
        is: (val) => val && val.includes('ko'),
        then: (schema) => schema.required(t('guide.validation.subtitle_required')),
      }),
    subtitle_zh: Yup.string()
      .trim()
      .when('support_language', {
        is: (val) => val && val.includes('zh'),
        then: (schema) => schema.required(t('guide.validation.subtitle_required')),
      }),
    subtitle_ja: Yup.string()
      .trim()
      .when('support_language', {
        is: (val) => val && val.includes('ja'),
        then: (schema) => schema.required(t('guide.validation.subtitle_required')),
      }),
  });

  const {
    register,
    handleSubmit,
    watch,
    reset,
    setValue,
    formState: { errors, isDirty },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      category: '',
      support_language: guide_id ? [] : [i18n.language], // 기본값으로 한국어 선택
      is_private: false,
      password: '',
      duration: { hours: 0, minutes: 0 },
      active: true,
      title_en: '',
      title_ko: '',
      title_zh: '',
      title_ja: '',
      subtitle_en: '',
      subtitle_ko: '',
      subtitle_zh: '',
      subtitle_ja: '',
    },
  });

  const watchCategory = watch('category');
  const watchIsPrivate = watch('is_private');
  const watchSupportLanguage = watch('support_language');
  const watchDurationHour = watch('duration.hours');
  const watchDurationMin = watch('duration.minutes');
  const duration = `${watchDurationHour}:${watchDurationMin}`;

  useEffect(() => {
    if (existingGuide) {
      reset({
        category: existingGuide.category,
        support_language: existingGuide.support_language,
        is_private: existingGuide.is_private.toString(),
        password: existingGuide.password,
        duration: {
          hours: parseInt(existingGuide.time.split(':')[0]),
          minutes: parseInt(existingGuide.time.split(':')[1]),
        },
        is_private: existingGuide.is_private.toString(),
        active: existingGuide.active.toString(),
        title_en: existingGuide.title_en,
        title_ko: existingGuide.title_ko,
        title_zh: existingGuide.title_zh,
        title_ja: existingGuide.title_ja,
        subtitle_en: existingGuide.subtitle_en,
        subtitle_ko: existingGuide.subtitle_ko,
        subtitle_zh: existingGuide.subtitle_zh,
        subtitle_ja: existingGuide.subtitle_ja,
      });

      if (existingGuide.cover_image) {
        setImage({
          id: existingGuide.cover_image._id,
          preview: `${config.API_SERVER_HOST}/files/guide/${existingGuide.cover_image.filename}?x=1280x720`,
          isExisting: true,
        });
      }

      setTimeout(() => {
        if (existingGuide.support_language.includes(i18n.language)) {
          setSelectedLang(i18n.language);
        } else {
          setSelectedLang(existingGuide.support_language[0]);
        }
      });
    }
  }, [existingGuide, reset]);

  useEffect(() => {
    if (!watchSupportLanguage.includes(selectedLang)) {
      setSelectedLang(watchSupportLanguage[0]);
    }
  }, [watchSupportLanguage]);

  const guideData = {
    category: watchCategory,
    image: image?.preview,
    support_language: watchSupportLanguage,
    title_en: watch('title_en') || '',
    title_ko: watch('title_ko') || ' ',
    title_zh: watch('title_zh') || ' ',
    title_ja: watch('title_ja') || ' ',
    subtitle_en: watch('subtitle_en') || '',
    subtitle_ko: watch('subtitle_ko') || ' ',
    subtitle_zh: watch('subtitle_zh') || ' ',
    subtitle_ja: watch('subtitle_ja') || ' ',
    points: existingGuide?.points || [],
  };

  const onSubmit = async (data) => {
    if (isSubmitting) return;

    setIsSubmitting(true);
    setSubmitError(null);

    const formData = new FormData();

    const hours = String(data.duration.hours).padStart(2, '0');
    const minutes = String(data.duration.minutes).padStart(2, '0');
    const time = `${hours}:${minutes}`;

    Object.entries(data).forEach(([key, value]) => {
      if (key === 'duration') {
        formData.append('time', time);
      } else if (key === 'support_language') {
        value.forEach((lang) => formData.append('support_language[]', lang));
      } else if (key === 'is_private' || key === 'active') {
        formData.append(key, value == true || value == 'true');
      } else {
        formData.append(key, value);
      }
    });

    if (image && !image.isExisting) {
      const response = await fetch(image.preview);
      const blob = await response.blob();
      const fileName = 'file_' + new Date().getTime() + '.' + blob.type.split('/')[1];
      formData.append('image', blob, fileName);
    }

    if (deleteImageId) {
      formData.append('delete_image_id', deleteImageId);
    }

    // formData.forEach((value, key) => {
    //   console.log(key, value);
    // });

    try {
      if (guide_id) {
        await updateGuide({ id: guide_id, guide: formData }).unwrap();
        navigate(`/guides/${guide_id}`);
      } else {
        const result = await newGuide(formData).unwrap();
        navigate(`/guides/${result.id}`);
      }
    } catch (err) {
      console.error('Failed to save guide:', err);
      setSubmitError(t('guide.submit_error'));
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file && file.type.substr(0, 5) === 'image') {
      setImage({
        preview: URL.createObjectURL(file),
        isExisting: false,
      });
    } else {
      setImage(null);
    }
  };

  const handleRemoveImage = () => {
    if (image.isExisting) {
      setDeleteImageId(image.id);
    }
    setImage(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  const handleLanguageChange = (e) => {
    const { value, checked } = e.target;
    const currentLanguages = watchSupportLanguage;

    if (checked && !currentLanguages.includes(value)) {
      setValue('support_language', [...currentLanguages, value]);
    } else if (!checked && currentLanguages.includes(value)) {
      if (currentLanguages.length > 1) {
        setValue(
          'support_language',
          currentLanguages.filter((lang) => lang !== value)
        );
      } else {
        // 마지막 언어를 선택 해제하려고 할 때
        e.preventDefault(); // 체크박스 상태 변경 방지
      }
    }
  };

  if (isLoadingGuide) {
    return <LoadingSpinner isLoading={true} />;
  }

  return (
    <Layout>
      <LoadingOverlay isLoading={isSubmitting} />
      <div className="grid grid-cols-1 gap-4 py-6 mx-auto max-w-7xl sm:px-6 lg:px-8 md:grid-cols-4 2xl:grid-cols-3">
        <div className="col-span-1 md:col-span-2 2xl:col-span-2">
          <form onSubmit={handleSubmit(onSubmit)} className="">
            <div className="overflow-hidden bg-white shadow p-7 sm:rounded-2xl">
              <div>
                <div className="font-bold text-black">{t('guide.category')}</div>
                <div className="flex flex-row flex-wrap mt-2 gap-x-5 gap-y-3">
                  <div className="flex items-center">
                    <input type="radio" id="category-museum" value="museum" {...register('category')} className="relative w-6 h-6 mr-2 border border-gray-300 rounded-full appearance-none checked:bg-indigo-500 checked:border-none" />
                    <label htmlFor="category-museum">{t('guide.categories.museum')}</label>
                  </div>
                  <div className="flex items-center">
                    <input type="radio" id="category-art_gallery" value="art_gallery" {...register('category')} className="relative w-6 h-6 mr-2 border border-gray-300 rounded-full appearance-none checked:bg-indigo-500 checked:border-none" />
                    <label htmlFor="category-art_gallery">{t('guide.categories.art_gallery')}</label>
                  </div>
                  <div className="flex items-center">
                    <input type="radio" id="category-historical_site" value="historical_site" {...register('category')} className="relative w-6 h-6 mr-2 border border-gray-300 rounded-full appearance-none checked:bg-indigo-500 checked:border-none" />
                    <label htmlFor="category-historical_site">{t('guide.categories.historical_site')}</label>
                  </div>
                  <div className="flex items-center">
                    <input type="radio" id="category-nature_and_park" value="nature_and_park" {...register('category')} className="relative w-6 h-6 mr-2 border border-gray-300 rounded-full appearance-none checked:bg-indigo-500 checked:border-none" />
                    <label htmlFor="category-nature_and_park">{t('guide.categories.nature')}</label>
                  </div>
                  <div className="flex items-center">
                    <input type="radio" id="category-city_tour" value="city_tour" {...register('category')} className="relative w-6 h-6 mr-2 border border-gray-300 rounded-full appearance-none checked:bg-indigo-500 checked:border-none" />
                    <label htmlFor="category-city_tour">{t('guide.categories.city_tour')}</label>
                  </div>
                </div>
                {errors.category && <p className="mt-2 text-sm text-red-600">{errors.category.message}</p>}
              </div>

              <div className="my-10">
                <label htmlFor="guide-image" className="font-bold text-black">
                  {t('guide.representative_image')}
                </label>
                <p className="mt-2 text-gray-500">{t('guide.image_guidance')}</p>
                <div className="flex mt-2 space-x-2">
                  <button type="button" className="inline-flex items-center px-2 py-2 text-sm font-bold bg-white border shadow-sm rounded-xl border-custom-personal text-custom-personal hover:bg-gray-50" onClick={() => fileInputRef.current.click()}>
                    <svg className="w-5 h-5 mr-2 -ml-1 text-custom-personal" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                      <path fillRule="evenodd" d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z" clipRule="evenodd" />
                    </svg>
                    {t('guide.upload_image')}
                  </button>
                  <input ref={fileInputRef} type="file" id="guide-image" name="guide-image" accept="image/*" className="hidden" onChange={handleImageChange} />
                </div>
                <div className="flex items-center mt-2">
                  <div className="relative flex items-center justify-center w-full h-48 max-w-md mt-2 bg-gray-100">
                    {/* <div className="relative flex items-center justify-center h-40 overflow-hidden bg-gray-100 rounded-lg w-72"> */}
                    {image ? (
                      <>
                        <img src={image.preview} alt="Preview" className="object-cover w-full h-full rounded-lg" />
                        <button type="button" onClick={handleRemoveImage} className="absolute p-1 text-white bg-red-500 rounded-full top-2 right-2 hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500">
                          <X className="w-4 h-4" />
                        </button>
                      </>
                    ) : constants.common.CATEGORY_IMAGE_MAP[watchCategory] ? (
                      <img src={constants.common.CATEGORY_IMAGE_MAP[watchCategory]} alt="Preview" className="object-cover w-full h-full rounded-lg" />
                    ) : (
                      <svg className="w-12 h-12 text-gray-300" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z" />
                      </svg>
                    )}
                  </div>
                </div>
                <p className="mt-2 text-gray-500">{t('guide.image_aspect_ratio_guidance')}</p>
                <p className="font-medium text-red-500">{t('guide.default_image_notice')}</p>
              </div>

              <div className="mt-6">
                <label htmlFor="duration" className="font-bold text-black">
                  {t('guide.estimated_duration')}
                </label>
                <div className="flex mt-2 rounded-md">
                  <input type="number" {...register('duration.hours')} className="flex-grow-0 px-2 py-1 pl-3 border border-r-0 border-gray-300 rounded-none focus:ring-indigo-500 focus:border-indigo-500 w-13 sm:text-sm" placeholder="00" min="0" max="23" />
                  <span className="inline-flex items-center px-3 text-gray-500 border border-l-0 border-r-0 border-gray-300 rounded-none bg-gray-50 sm:text-sm">{t('guide.hours')}</span>
                  <input type="number" {...register('duration.minutes')} className="flex-grow-0 px-2 py-1 pl-3 border border-l-0 border-gray-300 rounded-none focus:ring-indigo-500 focus:border-indigo-500 w-13 sm:text-sm" placeholder="00" min="0" max="59" />
                  <span className="inline-flex items-center px-3 text-gray-500 border border-l-0 border-gray-300 rounded-none rounded-r-md bg-gray-50 sm:text-sm">{t('guide.minutes')}</span>
                </div>
                {errors.duration && <p className="mt-2 text-sm text-red-600">{errors.duration.message || t('guide.validation.duration_required')}</p>}
              </div>
            </div>
            <div className="overflow-hidden bg-white shadow p-7 mt-7 sm:rounded-2xl">
              <div className="mt-6">
                <label className="font-semibold text-gray-700">{t('guide.supported_languages')}</label>
                <div className="grid grid-cols-2 gap-4 mt-2 sm:grid-cols-4 ">
                  {constants.common.LANGUAGES.map((lang) => (
                    <div key={lang.code} className="flex items-center">
                      <input
                        type="checkbox"
                        id={`lang-${lang.code}`}
                        value={lang.code}
                        {...register('support_language')}
                        onChange={handleLanguageChange}
                        checked={watchSupportLanguage.includes(lang.code)}
                        className="relative w-5 h-5 text-indigo-600 border border-gray-300 rounded-full appearance-none checked:bg-custom-personal checked:border-none"
                      />
                      <label htmlFor={`lang-${lang.code}`} className="block ml-2 text-sm text-gray-900">
                        {lang.name}
                      </label>
                    </div>
                  ))}
                </div>
                {errors.support_language && <p className="mt-2 text-sm text-red-600">{errors.support_language.message}</p>}
              </div>

              {watchSupportLanguage.length > 0 && (
                <div className="mt-6 space-y-7">
                  {constants.common.LANGUAGES.filter((lang) => watchSupportLanguage.includes(lang.code)).map((lang) => (
                    <div key={lang.code} className="mt-10">
                      <div className="flex items-center gap-2 mb-4 ">
                        <div className="flex items-center justify-center w-8 h-8 text-sm text-white bg-black rounded-full">{lang.code.toUpperCase()}</div>
                        <h3 className="text-xl font-bold text-gray-900">{lang.name}</h3>
                      </div>
                      <div className="mb-4">
                        <label htmlFor={`title-${lang.code}`} className="flex items-center font-bold text-black">
                          {t('guide.main_title')}
                          <InfoTooltip content={t('guide.main_title_info')} />
                        </label>
                        <input
                          type="text"
                          id={`title-${lang.code}`}
                          {...register(`title_${lang.code}`, {
                            onChange: () => {
                              if (lang.code !== selectedLang) {
                                setSelectedLang(lang.code);
                              }
                            },
                          })}
                          className="w-full py-1 mt-1 border-b border-gray-300 focus:outline-none"
                          placeholder={t(`guide.placeholders.main_title.${lang.code}`)}
                        />
                        {errors[`title_${lang.code}`] && <p className="mt-2 text-sm text-red-600">{errors[`title_${lang.code}`].message}</p>}
                      </div>
                      <div>
                        <label htmlFor={`subtitle-${lang.code}`} className="flex items-center font-bold text-black">
                          {t('guide.subtitle')}
                          <InfoTooltip content={t('guide.subtitle_info')} />
                        </label>
                        <input
                          type="text"
                          id={`subtitle-${lang.code}`}
                          {...register(`subtitle_${lang.code}`, {
                            onChange: () => {
                              if (lang.code !== selectedLang) {
                                setSelectedLang(lang.code);
                              }
                            },
                          })}
                          className="w-full py-1 mt-1 border-b border-gray-300 focus:outline-none"
                          placeholder={t(`guide.placeholders.subtitle.${lang.code}`)}
                        />
                        {errors[`subtitle_${lang.code}`] && <p className="mt-2 text-sm text-red-600">{errors[`subtitle_${lang.code}`].message}</p>}
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
            <div className="overflow-hidden bg-white shadow p-7 mt-7 sm:rounded-2xl">
              <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-2">
                <div className="flex gap-5 sm:col-span-1">
                  <label className="flex items-center font-bold text-black break-keep">
                    {/* <Lock className="w-4 h-4" /> */}
                    {t('guide.visibility_setting')}
                  </label>
                  {/* <select {...register('is_private')} className="block w-full py-2 pl-3 pr-10 mt-2 border border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500">
                    <option value="false">{t('guide.public')}</option>
                    <option value="true">{t('guide.private')}</option>
                  </select> */}
                  <div className="flex flex-row flex-wrap gap-x-5 gap-y-3">
                    <div className="flex items-center">
                      <input type="radio" id="public" value="false" {...register('is_private')} className="relative w-6 h-6 mr-2 border border-gray-300 rounded-full appearance-none checked:bg-indigo-500 checked:border-none" />
                      <label htmlFor="public">{t('guide.public')}</label>
                    </div>
                    <div className="flex items-center">
                      <input type="radio" id="private" value="true" {...register('is_private')} className="relative w-6 h-6 mr-2 border border-gray-300 rounded-full appearance-none checked:bg-indigo-500 checked:border-none" />
                      <label htmlFor="private">{t('guide.private')}</label>
                    </div>
                  </div>
                </div>

                <div className="flex gap-5 sm:col-span-1">
                  <label className="flex items-center font-bold text-black break-keep">
                    {/* <ToggleRight /> */}
                    {t('guide.activation_status')}
                  </label>
                  {/* <select {...register('active')} className="w-full py-2 pl-3 pr-10 mt-1 border border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500">
                    <option value="true">{t('guide.active')}</option>
                    <option value="false">{t('guide.inactive')}</option>
                  </select> */}
                  <div className="flex flex-row flex-wrap gap-x-5 gap-y-3">
                    <div className="flex items-center">
                      <input type="radio" id="active" value="true" {...register('active')} className="relative w-6 h-6 mr-2 border border-gray-300 rounded-full appearance-none checked:bg-indigo-500 checked:border-none" />
                      <label htmlFor="active">{t('guide.active')}</label>
                    </div>
                    <div className="flex items-center">
                      <input type="radio" id="inactive" value="false" {...register('active')} className="relative w-6 h-6 mr-2 border border-gray-300 rounded-full appearance-none checked:bg-indigo-500 checked:border-none" />
                      <label htmlFor="inactive">{t('guide.inactive')}</label>
                    </div>
                  </div>
                </div>
                {watchIsPrivate == 'true' && (
                  <div className="sm:col-span-1">
                    <label htmlFor="password" className="font-bold text-black">
                      {t('guide.password')}
                    </label>
                    <input type="password" id="password" {...register('password')} className="w-full py-1 mt-2 border-b border-gray-300 focus:outline-none" placeholder={t('guide.enter_password')} />
                    {errors.password && <p className="mt-1 text-xs text-red-600">{errors.password.message}</p>}
                  </div>
                )}
              </div>
            </div>
            {submitError && <div className="mt-4 text-red-600">{submitError}</div>}

            <div className="flex justify-end mt-6">
              <button
                type="submit"
                disabled={isSubmitting}
                className={`inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white ${
                  isSubmitting ? 'bg-indigo-400 cursor-not-allowed' : 'bg-indigo-600 hover:bg-indigo-700'
                } focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
              >
                {isSubmitting ? t('guide.submitting') : guide_id ? t('guide.update_guide') : t('guide.create_guide')}
              </button>
            </div>
          </form>
        </div>
        <div className="hidden col-span-1 md:col-span-2 2xl:col-span-1 md:block">
          <div className="sticky top-4">
            <GuidePreview guideData={guideData} duration={duration} selectedLang={selectedLang} onLanguageChange={setSelectedLang} />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default GuideForm;
