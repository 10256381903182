import React, { useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import Header from '../components/common/Header';
import Footer from '../components/common/Footer';
import { useGetLatestTermsQuery } from '../services/termsService';
import constants from '@constants';
import LoadingSpinner from '@components/LoadingSpinner';

function TermsOfService() {
  const { t, i18n } = useTranslation();

  const searchParams = new URLSearchParams(window.location.search);

  const { data, error, isLoading, isSuccess, isError } = useGetLatestTermsQuery(i18n.language, {
    refetchOnMountOrArgChange: true,
  });

  const refreshPage = useCallback(() => {
    window.location.reload();
  }, []);

  useEffect(() => {
    if (searchParams.get('lang') && constants.common.LANGUAGES.filter((lang) => lang.code === searchParams.get('lang'))) {
      i18n.changeLanguage(searchParams.get('lang'));
    }
  }, []);

  useEffect(() => {
    let timer;
    if (isLoading) {
      timer = setTimeout(() => {
        refreshPage();
      }, 1000); // 1초 후 새로고침
    }
    return () => {
      if (timer) clearTimeout(timer);
    };
  }, [isLoading, isSuccess, isError, data, error, refreshPage]);

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (error || data?.contents.length === 0) {
    return (
      <div className="flex flex-col min-h-screen font-sans text-gray-800 bg-gray-100">
        <Header />
        <main className="container flex items-center justify-center flex-grow px-4 py-8 mx-auto">
          <div className="w-full max-w-lg p-4 text-red-700 bg-red-100 border-l-4 border-red-500 rounded-md shadow-md">
            <div className="flex items-center mb-2">
              <svg className="w-6 h-6 mr-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z" clipRule="evenodd" />
              </svg>
              <p className="text-lg font-bold">{t('error.termsTitle')}</p>
            </div>
            <p className="mb-2">{t('error.loadingTerms')}</p>
            <p>{t('error.tryAgainLater')}</p>
          </div>
        </main>
        <Footer />
      </div>
    );
  }

  // HTML 문자열에 스타일 클래스를 추가하는 함수
  const addStyleToHtml = (html) => {
    return html
      .replace(/<h1/g, '<h1 class="mt-8 mb-6 text-3xl font-bold border-b pb-4"')
      .replace(/<h2/g, '<h2 class="mt-6 mb-4 text-lg font-semibold"')
      .replace(/<h3/g, '<h3 class="font-semibold mb-3 mt-4"')
      .replace(/<p/g, '<p class="mb-4"')
      .replace(/<ul/g, '<ul class="list-disc pl-5 mb-4"')
      .replace(/<li/g, '<li class="mb-2"');
  };

  const styledHtml = addStyleToHtml(data?.contents?.[0].content || '');

  return (
    <div className="font-sans text-gray-800 bg-gray-100">
      <Header />
      <main className="flex flex-col items-center px-12 py-8">
        <div className="max-w-[1200px]">
          <h1 className="mb-8 text-4xl font-bold">{data.title}</h1>
          <div className="prose max-w-none" dangerouslySetInnerHTML={{ __html: styledHtml }} />
          <div className="mt-8 text-sm text-gray-600">
            <p>버전: {data.version}</p>
            <p>발효일: {new Date(data.effectiveDate).toLocaleDateString()}</p>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
}

export default TermsOfService;
